import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ContentLoader from "react-content-loader";

import { SearchConfig } from "@ultracommerce/react-storefront/global";
import { PotentialFilters } from "@ultracommerce/react-storefront/global/src/interface/SearchProduct";
import { useElementContext } from "@ultracommerce/react-storefront/global";

const FilterLoader = () => (
  <ContentLoader speed={2} viewBox="0 0 400 200" className="listingSidebarLoader">
    <rect x="25" y="15" rx="5" ry="5" width="350" height="20" />
    <rect x="25" y="45" rx="5" ry="5" width="350" height="10" />
    <rect x="25" y="60" rx="5" ry="5" width="350" height="10" />
    <rect x="26" y="75" rx="5" ry="5" width="350" height="10" />
    <rect x="27" y="107" rx="5" ry="5" width="350" height="20" />
    <rect x="26" y="135" rx="5" ry="5" width="350" height="10" />
    <rect x="26" y="150" rx="5" ry="5" width="350" height="10" />
    <rect x="27" y="165" rx="5" ry="5" width="350" height="10" />
  </ContentLoader>
);

export interface ListingSidebarProps {
  config: SearchConfig;
  isFetching: boolean;
  hide: string;
  filtering: PotentialFilters;
  recordsCount: number;
  updateAttribute: (data: { name: string; filterName: string }) => void;
}

export const ListingSidebar = ({
  config,
  isFetching,
  hide,
  filtering,
  updateAttribute,
  recordsCount,
}: ListingSidebarProps) => {
  const { t } = useTranslation();
  const {
    ProductListingModule,
    CommonModule: { SearchBar },
  } = useElementContext();

  const hiddenFacets = ['productType','marketPath']

  const filters = useMemo(
    () =>
      Object.entries(filtering)
        .map(([type, filter]) => filter)
        .filter((filter) => filter.options.length && !hide.includes(filter.slug) && !hiddenFacets.includes(filter.slug))
        .sort((a, b) => a.sortOrder - b.sortOrder),
    // eslint-disable-next-line
    [filtering, hide],
  );

  if (recordsCount < 1 && !isFetching) return null;

  return (
    
    <div className="col-lg-3">
    {isFetching && (
      <>
        <FilterLoader />
        <FilterLoader />
        <FilterLoader />
      </>
    )}

    {!isFetching && (
         <nav className="navbar navbar-expand-lg navbar-light py-0 px-xs-3">
         <button className="navbar-toggler collapsed filterbar-toggle d-block d-lg-none p-2 mb-4 text-black" type="button" data-bs-toggle="collapse" data-bs-target="#filterBarCollapse" aria-controls="filterBarCollapse" aria-expanded="false" aria-label="Toggle Filters">
           <span>Filters</span> <i className="bi bi-toggles2"></i>
         </button>

         <div className="collapse navbar-collapse" id="filterBarCollapse">
           <div className="filter-block p-4">
             <h4 className="border-bottom pb-2 mb-3">{t('frontend.product.filterBy')}</h4>
          <SearchBar redirectToSearchPage={false} />
          {filters.map((filter) => (
            <div key={filter.slug}>
              <ProductListingModule.CoreComponents.FacetFilter
                config={config}
                filter={filter}
                facetKey={`facet_${filter.slug}`}
                updateAttribute={updateAttribute}
              />
            </div>
          ))}
        </div>
        </div>
    </nav>
)}
  </div>

  );
};
