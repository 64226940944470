import { FacetFilterProps } from "@ultracommerce/react-storefront/global/src/modules/ProductListing/Components/FacetFilter/FacetFilter.componentModel";
import { useComponentData } from "@ultracommerce/react-storefront/global/src/modules/ProductListing/Components/FacetFilter/FacetFilter.componentService";
import { FacetFilterView } from "./FacetFilter.componentView";

const FacetFilter = (props: FacetFilterProps) => {
  const { config, filter, facetKey = "" } = props;
  const { componentData, componentService } = useComponentData(props);

  if (!filter.options.length) return null;
  if (config?.excludeFacets?.includes(facetKey)) return null;

  return <FacetFilterView componentData={componentData} {...componentService} />;
};

export { FacetFilter };
