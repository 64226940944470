import { SearchListingProps } from "@ultracommerce/react-storefront/global/src/modules/ProductListing/Components/SearchListing/SearchListing.componentModel";
import { SearchListingView } from "./SearchListing.componentView";
import { useComponentData } from "@ultracommerce/react-storefront/global/src/modules/ProductListing/Components/SearchListing/SearchListing.componentService";

const SearchListing = (props: SearchListingProps) => {
    const { componentData, componentService } = useComponentData(props);

    return <SearchListingView componentData={componentData} setViewMode={componentService.setViewMode} />;
};

export { SearchListing, type SearchListingProps };